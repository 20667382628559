import { Exchange } from "../v2/data/CryptoDataTypeV2";

export let IMPOTS_DEFAULT_PERC:number=33;//(33.0%)

export let exchangesConfigArray:Exchange[] = [
    { exchange: 'BITGET', key: 'Ne fonctionne pas encore', secret: 'Ne fonctionne pas encore',passphrase:'Votre passphrase', exclusions: 'Ne fonctionne pas encore',editable:true ,buyUrl:'https://www.bitget.com/fr/spot/{placeholder}USDT'}
    ,{ exchange: 'BITMART', key: 'Votre clé Bitmart', secret: 'Votre secret Bitmart',passphrase:'Votre passphrase', exclusions: 'Cryptos séparées par une virgule',editable:true, buyUrl:'https://www.bitmart.com/trade/en-US?symbol={placeholder}_USDT' }
    ,{ exchange: 'BITRUE', key: 'Votre clé Bitrue', secret: 'Votre secret Bitrue',passphrase:'Votre passphrase', exclusions: 'Cryptos séparées par une virgule',editable:true, buyUrl:'https://www.bitrue.com/fr/trade/{placeholder}_usdt' }
    ,{ exchange: 'BLOFIN',key:'Votre clé Blofin',secret:'Votre secret Blofin',passphrase:'Votre passphrase',exclusions:'',editable:true,buyUrl:'https://blofin.com/spot/{placeholder}-USDT'}
    ,{ exchange: 'BYBIT',key:'',secret:'',passphrase:'',exclusions:'',editable:false,buyUrl:'https://www.bybit.com/en/trade/spot/{placeholder}/USDT'}
    ,{ exchange: 'GATE.IO', key: 'Ne fonctionne pas encore', secret: 'Ne fonctionne pas encore',passphrase:'Votre passphrase', exclusions: 'Ne fonctionne pas encore',editable:true,buyUrl:'https://www.gate.io/fr/trade/{placeholder}_USDT' }
    ,{ exchange: 'MEXC', key: 'Votre clé MEXC', secret: 'Votre secret MEXC',passphrase:'Votre passphrase', exclusions: 'Cryptos séparées par une virgule',editable:true,buyUrl:'https://www.mexc.com/fr-FR/exchange/{placeholder}_USDT' }
    ,{ exchange: 'TRADEOGRE',key:'',secret:'',passphrase:'',exclusions:'',editable:false,buyUrl:'https://tradeogre.com/exchange/{placeholder}-USDT'}  
    ,{ exchange: 'XT',key:'',secret:'',passphrase:'',exclusions:'',editable:false,buyUrl:'https://www.xt.com/en/trade/{placeholder}_usdt'}  
  ];

export const getTargetColor = (level:number,transparency:string) =>{
    if(level<0){
        return "rgba(0,0,0,0)";
    }else if (level >= 80) {
        return "rgba(100,255,100,"+transparency+")";
    } else if (level >= 50) {
        return "rgba(238, 173, 0,"+transparency+")";
    } else {
        return "rgba(100,100,100,"+transparency+")";
    }

}