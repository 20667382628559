import React from "react";
import "../../CryptoTable.css";
import { BarChart } from "@mui/x-charts";
import { valueFormatter } from "./CryptoGraphUtils";
import { porttcfolio } from "../../data/CryptoData";

interface ChildProps {
}

const barChartColors = [
    'rgb(234,27,0)',
    'rgb(238,173,0)'
  ]

const chartSetting = {
    xAxis: [
        {
            label: 'Montant (USDT)',
        },
    ],
    width: 1100,
    height: 400,
};


const renderBarChart = () => {
    let dataTarget: any[] = (porttcfolio.transient != null && porttcfolio.transient.cryptoTargets) ? porttcfolio.transient.cryptoTargets : [];
    let dataCurrent: any[] = (porttcfolio.transient != null && porttcfolio.transient.cryptos) ? porttcfolio.transient.cryptos : [];
    let data: any[] = [];

    let total = 0;
    for (let item of dataCurrent) {
        for (let itemTarget of dataTarget) {
            if (item.symbol === itemTarget.symbol) {
                data.push({ label2: item.label2, value: item.value, maxTarget: itemTarget.value });
                break;
            }
        }
        total += item.total;
    }
    return <BarChart
        dataset={data}
        colors={barChartColors}
        yAxis={[{ scaleType: 'band', dataKey: 'label2' }]}
        series={[
            { dataKey: 'value', label: 'Montant actuel (' + total.toFixed(0) + ' USDT)', valueFormatter: valueFormatter },
            { dataKey: 'maxTarget', label: 'Objectif (' + porttcfolio.transient?.totalTarget.toFixed(0) + ' USDT)', valueFormatter: valueFormatter }
        ]}
        margin={{left: 140 }}
        layout="horizontal"
        {...chartSetting}
    />;
}

const CryptoMultiBarPerSymbolV2: React.FC<ChildProps> = (props: ChildProps) => {
    return (<span  className="cryptoMultiBar">{renderBarChart()}</span>);
};

export default CryptoMultiBarPerSymbolV2;