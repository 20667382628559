import { CryptoExchangeV2 } from "../data/CryptoDataTypeV2";

export const usedBagPercV2 = (cryptoExchangeV2: CryptoExchangeV2) => {
    let total: number = 0;
    if (cryptoExchangeV2) {
      let targets = cryptoExchangeV2.targets;
      if (targets) {
        for (let i = 0; i < targets.length; i++) {
          let target = targets[i];
          let bagPerc: number = (target.bagPerc === undefined || target.bagPerc == null) ? 0 : Number(target.bagPerc);
          total = total + bagPerc;
        }
      }
    }
    return total;
  }