import * as React from 'react';

import Box from '@mui/material/Box';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import {forwardRef}from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { AddCircle } from '@mui/icons-material';

interface ChildProps {
    open:boolean;
  }

  
export type ListingRef = {
    toggleMe: () => void;
}

    const CryptoHelp= forwardRef(( props:any,ref:any  ) => {

  const [state, setState] = React.useState({

    right: props.open,

  });

  React.useImperativeHandle(ref, () => ({
    toggleMe: () =>{
        setState({right:!state.right});
    }
  }));



  const toggleDrawer =

    (anchor: string, open: boolean) =>

    (event: React.KeyboardEvent | React.MouseEvent) => {

      if (

        event &&

        event.type === 'keydown' &&

        ((event as React.KeyboardEvent).key === 'Tab' ||

          (event as React.KeyboardEvent).key === 'Shift')

      ) {

        return;

      }

 

      setState({ ...state, [anchor]: open });

    };

 


  const list = (anchor: string) => (

    <Box

      sx={{ width: 700 }}

      role="presentation"

      onClick={toggleDrawer(anchor, false)}

      onKeyDown={toggleDrawer(anchor, false)}

    >
<h1>Présentation</h1>
<div>Ce petit outil a été concu pour suivre le DCA de vente d'un portefeuille lors du prochain bullrun.
  <br/>Le but est de saisir les crypto de son portefeuille et de définir par paliers de 20% les objectifs de vente
  <br/>L'interface est composée de 3 parties:
  <ul>
    <li>Haut de l'écran:Le récapitulatif des prévisions de vente</li>
    <li>Milieu: le tableau des cryptos avec leur cibles de vente</li>
    <li>Bas: 3 graphes récapitulatifs ( répartition actuelle du portefeuille, répartition au prix optimal, et répartition actuelle par exchange)</li>
  </ul>
</div>
<h1>1ère utilisation</h1>
<div>Les données affichées au premier passage sont des données de démo..
Vous pouvez les modifier en éditant directement le tableau (bouton "Editer tableau") ou en important vos données au format JSON (icone "Uploader portefeuille").
Les données sont sauvegardées dans le cache du navigateur. Il n'y a aucune sauvegarde sur le serveur.
</div>
<h1>Interface</h1>
<h3>Récapitulatif des prévisions de vente</h3>
<div>Dans cette partie, vous pouvez trouver une estimation des montants vendus, restant a vendre, impots, gains,... pour les différents palliers.
  Par exemple pour l'objectif 20% l'application calcul le montant que vous auriez si vous vendiez tous les bags pour lesquels vous auriez saisi un Objectif 1 de 20% de bag.
  Pour l'objectif 40%, l'application le montant que vous auriez si vous vendiez tous les bags pour lesquels vous auriez saisi un Objectif 1 de 20% de bag plus tous ceux pour lesquels vous auriez saisi un Objectif 2 de 40% de bag.
Et ainsi de suite.

</div>
<h3>Tableau des cryptos</h3>
<div>Les taux affichés sont récupérés toutes les demi heures auprès de coingecko. Toutes les cryptos ne sont malheureusement pas gérées</div>
<div>Le tableau devient éditable en cliquant sur le bouton  "Editer tableau".</div>

<h4>Mode consultation</h4>
La couleur de fond d'un objectif peut changer si l'objectif est atteint à un certain pourcentage.

<h4>Mode édition</h4>
En mode édtion il est possible de:
<ul>
  <li>Ajouter une ligne     <Tooltip title="Ajouter ligne"><IconButton>
      <AddCircle/>
    </IconButton></Tooltip></li>
    <li>Supprimer la ligne choisie <Tooltip title="Supprimer ligne">
    <IconButton>
      <RemoveIcon/>
    </IconButton>
    </Tooltip></li>
    <li>Editer les données d'une ligne
      <ul>
        <li>Remplir les champs texte</li>
        <li>Passer un objectif en
          <ul>
            <li>Initial: c'est le statu initial de l'order</li>
            <li>Order de vente: cela signifie que vous avez planifié un ordre de vente pour cet objectif et ce bag sur un exchange</li>
            <li>Vendu: cela signifie que l'objectif a été atteint, et le bag associé a été vendu</li>
            </ul>

        </li>
      <li>Ajouter un objectif <Tooltip title="Ajouter objectif"><IconButton>
      <AddCircle/>
    </IconButton></Tooltip></li>
    <li>Supprimer le dernier objectif <Tooltip title="Supprimer objectif">
    <IconButton>
      <RemoveIcon/>
    </IconButton>
    </Tooltip></li>
        </ul>
    </li>
</ul>

<h3>Upload des données</h3>
Les données sont chargées depuis le cache du navigateur. Pour sauver les changements, cliquez sur le bouton "Sauver".
Comme ce toolpeut évoluer, il est conseillé garder chez vous une copie du fichier.
<h4>Format du fichier</h4>
Pour respecter le format JSON:
<ul>
  <li>Le fichier JSON doit commencer par '[' et finir par ']'</li>
  <li>Il doit y avoir une ligne par crypto et par exchange. La dernière ligne crypto ne doit pas se terminer par ','.</li>
</ul>
<h4>Format d'une ligne Crypto</h4>
Voici la liste des parametres d"un ligne:

<table className="help">
  <tbody>
  <tr>
    <th>Nom</th>
    <th>Obligatoire?</th>
    <th>Description</th>
  </tr>
  <tr>
    <td>symbol</td>
    <td>oui</td>
    <td>Le code de la cryptomonnaie, en majuscule (ex: BTC, ETH, ...)</td>
  </tr>

  <tr>
    <td>ttcId</td>
    <td>oui</td>
    <td>L'identifiant unique de la ligne dans l'application. SI vous le saisissez vous même (format String), assurez vous qu'il soit unique.</td>
  </tr>
  
  <tr>
    <td>site</td>
    <td>oui</td>
    <td>champs texte libre, indiquant ou est la crypto (ex: blofin, ledger, metamask,...)</td>
  </tr>
  <tr>
    <td>bag</td>
    <td>oui</td>
    <td>La quantité de cryptomonnaie, séparateur: le point (exemple: 123.4567)</td>
  </tr>
  <tr>
    <td>invested</td>
    <td>non</td>
    <td>le montant investi dans ce bag, en USDT</td>
  </tr>
  <tr>
    <td>targets</td>
    <td>oui</td>
    <td>La liste des objectifs pour cette cryptomonnaie</td>
  </tr>

  <tr>
    <td>comment</td>
    <td>non</td>
    <td>votre note personnelle</td>
  </tr>
  </tbody>
</table>

<h4>Format d'une ligne target</h4>
La variable "targets" contient une liste de 0 à 5 targets, chaque target correspondant à 20% du bag.
<br/>Exemples pour le bitcoin:
<table className="help">
<tbody>
<tr>
  <td><pre>"target":[]</pre></td>
  <td>aucun objectif fixé</td>
  </tr>
<tr>
  <td><pre>"target":[&#123;"rate":50000&#125;]</pre></td>
  <td>vente de 20% a 50000</td>
  </tr>
<tr>
  <td><pre>"target":[&#123;"rate":50000&#125;,&#123;"rate":60000&#125;]</pre></td>
  <td>vente de 20% a 50000, puis de 20 à 60000</td>
  </tr>
  </tbody>
</table>
<br/>
Voici la liste des parametres d'une target:

<table className="help">
<tbody>
  <tr>
    <th>Nom</th>
    <th>Obligatoire?</th>
    <th>Description</th>
  </tr>
  <tr>
    <td>rate</td>
    <td>oui</td>
    <td>Le prix unitaire en USDT auquel on souhaite vendre ce pourcentage de bag </td>
  </tr>

  
  <tr>
    <td>sold</td>
    <td>non</td>
    <td>Valeurs possible: true/false. indique (pour les calculs et l'affichage) si ce pourcentage de bag a été vendu.</td>
  </tr>
  </tbody>
</table>

<h3>Download des données</h3>
<div>En cliquant sur l'icone "Download", vous pouvez récuperer sur votre pc une sauvegarde au format JSON.</div>
    </Box>

  );

 

  return (

    <SwipeableDrawer

            anchor={"right"}

            open={state["right"]}

            onClose={toggleDrawer("right", false)}

            onOpen={toggleDrawer("right", true)}

          >

            {list("right")}

          </SwipeableDrawer>

  );

  });

export default CryptoHelp;
