import axios from "axios";
import { calculPrice, porttcfolio } from "./CryptoData";
import { updateCacheCryptoExpiration, updatePortfolioInBrowserCache } from "./CryptoCache";

const delay = (ms: any) => new Promise(res => setTimeout(res, ms));

export const waitForAllLoaded = async (setTtcCryptos: any, filterData: any, forceUpdate:any) => {

  while (!(porttcfolio.transient && porttcfolio.transient.allLoaded)) {
    await delay(1000);
  }

  setTtcCryptos({ items: filterData([]) });
  if(forceUpdate){
    forceUpdate();
  }
}

export async function getCoingeckoIdSuggestions(symbol: string){
  const res = await axios.get("https://www.geeki.fr/api/cryptoSuggest/" + symbol);
  return res;
}

export async function loadPricesV2() {
  let symbolsArray: any[] = [];
  for (let i = 0; i < porttcfolio.cryptosV2.length; i++) {
    let cryptoV2 = porttcfolio.cryptosV2[i];
    symbolsArray.push({ symbol: cryptoV2.symbol, name: cryptoV2.name, coingeckoId: cryptoV2.coingeckoId });
  }

  getGeekiPricesV2(symbolsArray);
}

export async function getCurrencyRate(name: string){
  console.log("getCurrency:"+name);
  const res = await axios.get("https://www.geeki.fr/api/cryptos/currencies/" + name);
  console.log(res);
  return res;
}

export const getGeekiPricesV2 = (symbols: any[]) => {
console.log("getGeekiPricesV2");
console.log(symbols);

  if (symbols != null && symbols !== undefined) {
    if(porttcfolio.transient){
      let tra=porttcfolio.transient;
      tra.allLoaded=false;
    }
    axios
      .post(
        "https://www.geeki.fr/api/cryptos2/", { cryptos: symbols }, {
        withCredentials: false,
        headers: {
          'Accepts': 'application/json',
          'Access-Control-Allow-Credentials': true,
          "Access-Control-Allow-Origin": "*",
          'Access-Control-Allow-Headers': 'Origin'
        }
      })
      .then((res) => {
        let resData = res.data;
        for (let i = 0; i < resData.length; i++) {

          let data = resData[i];
          let cryptosV2 = porttcfolio.cryptosV2;
          let cryptoV2;
          for (let j = 0; j < cryptosV2.length; j++) {
            cryptoV2 = cryptosV2[j];
            cryptoV2.symbol = cryptoV2.symbol.split("|")[0];
            if (cryptoV2.symbol.toUpperCase() === data.symbol.toUpperCase()
              && (
                (
                  (data.coingeckoId != null)
                  || (
                    cryptoV2.name == null || cryptoV2.name === undefined
                    || cryptoV2.name!.toUpperCase() === data.name.toUpperCase())))) {

               
              cryptoV2.symbol = data.symbol.toUpperCase();
              cryptoV2.name = data.name;
              cryptoV2.coingeckoId = (data.coingeckoId!=null?data.coingeckoId:cryptoV2.coingeckoId);
              cryptoV2.unitPrice = data.price;

              cryptoV2.price_change_percentage_24h = isNaN(data.price_change_percentage_24h) ? Number(data.price_change_percentage_24h) : data.price_change_percentage_24h;
              cryptoV2.market_cap = data.market_cap;
              cryptoV2.market_cap_rank = data.market_cap_rank;
              cryptoV2.ath = data.ath;
              cryptoV2.image = data.image;
              cryptoV2.history = data.history;
              cryptoV2.cryptoBagPrice = 0;
              for (let k = 0; k < cryptoV2.exchanges.length; k++) {
                let exc = cryptoV2.exchanges[k];
                exc.exchangeBagPrice = calculPrice(exc.bag, data.price);
                cryptoV2.cryptoBagPrice += exc.exchangeBagPrice;
              }

              updateCacheCryptoExpiration(cryptoV2.symbol, cryptoV2.name, cryptoV2.unitPrice, data.coingeckoId, cryptoV2.history, data.price_change_percentage_24h);
            }
          }
        }
        if(porttcfolio.transient){
          let tra=porttcfolio.transient;
          tra.allLoaded=true;
        }
        updatePortfolioInBrowserCache();
      })
      .catch((error) => {
        if(porttcfolio.transient){
          let tra=porttcfolio.transient;
          tra.allLoaded=true;
        }
        console.log(error);
        updatePortfolioInBrowserCache();
      })
  }
}
