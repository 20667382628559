import "./Crypto.css";
import "./v2/bubble/CryptoBubbles.css";
import { isCookieAlreadyRead } from "./data/CryptoCookie";
import CryptoBubbles from "./v2/bubble/CryptoBubbles";
import CryptoTableV2 from "./v2/table/CryptoTableV2";
import { porttcfolio } from "./data/CryptoData";


export default function CryptoContent(object: any) { 

  if (object.buyPopupRef.current!=null && !isCookieAlreadyRead()){
    (object.buyPopupRef.current as any).handleOpen("CookieMode");
  }

  return (
    <>
      {porttcfolio.cryptosV2.length>0 && <CryptoBubbles/>}      
      <CryptoTableV2 cryptoRows={object} 
      buyPopupRef={object.buyPopupRef}
      partialRefresh={object.partialRefresh} fullRefresh={object.fullRefresh}  
      viewMode={object.viewMode}/>
      </>
  );
}
