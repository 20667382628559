import { CryptoExchangeV2, TargetDataV2 } from "../v2/data/CryptoDataTypeV2";
import { updatePortfolioInBrowserCache } from "./CryptoCache";
import { porttcfolio } from "./CryptoData";
import { generateTtcId } from "./CryptoDataType";

export const migrateToV2 = () => {
    if (porttcfolio.cryptosV2 === undefined) {
        porttcfolio.cryptosV2 = [];
    }

    if (porttcfolio.cryptosV2.length == 0) {

        for (let item of porttcfolio.items) {
            let foundItem = null;
            for (let i = 0; i < porttcfolio.cryptosV2.length; i++) {
                let crypto = porttcfolio.cryptosV2[i];
                if (crypto.symbol === item.symbol.toUpperCase()) {
                    foundItem = crypto;
                    break;
                }
            }
            if (foundItem == null) {
                foundItem = {
                    symbol: item.symbol,
                    ttcId: (item.ttcId?item.ttcId:generateTtcId()),
                    name: item.name,
                    image: item.image,
                    coingeckoId: (item.coingeckoId == null ? undefined : item.coingeckoId),
                    market_cap: item.market_cap,
                    market_cap_rank: item.market_cap_rank,
                    ath: item.ath,
                    unitPrice: item.unitPrice,
                    price_change_percentage_24h: item.price_change_percentage_24h,
                    comment: item.comment,
                    history: item.history,
                    cryptoBagPrice: 0,
                    exchanges: []
                };
                porttcfolio.cryptosV2.push(foundItem);
            }else{
                if (foundItem.coingeckoId==null){
                    foundItem.coingeckoId=(item.coingeckoId == null ? undefined : item.coingeckoId);
                }
                if (foundItem.image==null){
                    foundItem.image=item.image;
                }
                if (foundItem.market_cap_rank==null){
                    foundItem.market_cap_rank=(item.market_cap_rank==null?undefined:item.market_cap_rank);
                }
            }

            let targets:TargetDataV2[]=[];

            for (let target of item.targets) {
                let targetV2:TargetDataV2={
                    rate: target.rate,
                    price: target.price,
                    bag: target.bag,
                    bagPerc: target.bagPerc,
                    perc: target.perc,
                    status: target.status,
                    graphProcessed: target.graphProcessed,
                    reinvestedAmount:target.reinvestedAmount
                };
                targets.push(targetV2);
            }

            let exchange: CryptoExchangeV2 = {
                ttcId: (item.ttcId?item.ttcId:generateTtcId()),
                name: item.site,
                bag: item.bag,
                exchangeBagPrice: (item.bagPrice == null) ? 0 : item.bagPrice,
                invested: item.invested,
                automaticUpdate: item.automaticUpdate,
                updatedFromExchange: item.updatedFromExchange,
                targets: targets
            };
            (foundItem.exchanges as CryptoExchangeV2[]).push(exchange);

            foundItem.cryptoBagPrice += exchange.exchangeBagPrice;
        }

        porttcfolio.items=[];
        updatePortfolioInBrowserCache();
    }
}