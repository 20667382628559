import { useState } from "react";
import { Tooltip } from "@mui/material";
import { updatePortfolioInBrowserCache } from "../../data/CryptoCache";
import { getCoingeckoIdSuggestions } from "../../data/CryptoQuery";

const CoingeckoCodeEditor = (val: any) => {

    let [value] = useState(val);
    let cryptoV2 = value.cryptoV2;
    let key = value.objectKey;

    const [showList, setShowList] = useState(false);
    const [inputValue, setInputValue] = useState(cryptoV2[key]);
    const [coingeckoIdSuggestions, setCoingeckoIdSuggestions] = useState([]);

    const toggleList = () => {
        if (showList) {
            setShowList(!showList);
        } else {
            getCoingeckoIdSuggestions(cryptoV2.symbol).then((res: any) => {
                setCoingeckoIdSuggestions(res.data.result);
                setShowList(!showList);
            });
        }
    };

    const handleSelectItem = (item: any) => {
        cryptoV2[key] = item.code;
        updatePortfolioInBrowserCache();
        setInputValue(item.code);
        setShowList(false);
    };

    const blurMe = (newValue: any) => {
        cryptoV2[key] = newValue;
        updatePortfolioInBrowserCache();
        setInputValue(newValue);
    }

    if (value.viewMode) {
        return <Tooltip title={val.tooltip}><span className={"fieldEditor viewMode crypto-row-symbol fixedWidth"}>{cryptoV2[key]}</span></Tooltip>
    } else {
        let valueVal = inputValue;

        return (<Tooltip title={val.tooltip}>
            <span className={"fieldEditor editMode crypto-row-symbol fixedWidth"}><input type="text" defaultValue={valueVal}
                onBlur={e => blurMe(e.target.value)} className="fieldEditor text" />
                <button onClick={toggleList} className="ttcButton"> {showList ? 'Masquer la liste' : 'Afficher la liste'}</button>
                {showList && (
                    <ul>
                        {coingeckoIdSuggestions && coingeckoIdSuggestions.map((item: any) => (
                            <li
                                key={item.code}
                                onClick={() => handleSelectItem(item)}
                                style={{ cursor: 'pointer', margin: '5px 0' }}
                            >
                                {item.code}
                            </li>
                        ))}
                    </ul>
                )}</span></Tooltip>);
    }
}

export default CoingeckoCodeEditor;
