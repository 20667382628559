import axios from "axios";
import { exchangesDef } from "./CryptoData";
import { Exchange, ExchangeDef } from "../v2/data/CryptoDataTypeV2";

const geekiExchangesRequest = async (exchanges:Exchange[]) => {
  let data=[];
  for(let i=0;i<exchanges.length;i++){
    let exc=exchanges[i];
    if (exc.editable){
      data.push(exc);
    }
  }
    try {
        const response = await axios.post('https://www.geeki.fr/api/crypto/accounts', {exchanges:data}, {
          withCredentials: false,
          headers: {
            'Accepts': 'application/json',
            'Access-Control-Allow-Credentials': true,
            "Access-Control-Allow-Origin": "*",
            'Access-Control-Allow-Headers': 'Origin'
          }
        });        
        return response;
      } catch (error) {
        console.error('Erreur lors de l\'appel API:', error);
      }
  }

  export const geekiExchangesDefRequest = async (exchanges:ExchangeDef[]) => {
      try {
          const response = await axios.post('https://www.geeki.fr/api/crypto/exchanges', {exchanges:exchanges}, {
            withCredentials: false,
            headers: {
              'Accepts': 'application/json',
              'Access-Control-Allow-Credentials': true,
              "Access-Control-Allow-Origin": "*",
              'Access-Control-Allow-Headers': 'Origin'
            }
          });  
          exchangesDef.exchanges=response.data.exchanges; 
        } catch (error) {
          console.error('Erreur lors de l\'appel API:', error);
        }
    }

  export default geekiExchangesRequest;