import { useState } from "react";
import { Tooltip } from "@mui/material";
import { updatePortfolioInBrowserCache } from "../../data/CryptoCache";

const FieldEditorV2 = (val: any) => {
    let [value] = useState(val);
    let object=value.object;
    let key=value.objectKey;
    let myClassName = "fieldEditor " + val.type;
    
    const blurMe = (newValue: any) => {
        object[key] = newValue;
        updatePortfolioInBrowserCache();
    }

    if (value.viewMode) {
        return <Tooltip title={val.tooltip}><span className={myClassName+" viewMode " + val.className}>{val.label ? val.label : ""}{val.type === "currency" && object[key] ? "$ " : ""}{object[key]}</span></Tooltip>
    } else {
        let valueVal = object[key];
        return (<Tooltip title={val.tooltip}>
            <span className={"fieldEditor editMode " + val.className}>{val.label ? val.label : ""}{val.type === "currency" ? "$ " : ""}<input type="text" defaultValue={valueVal}
            onClick={value.onClick}
                onBlur={e => blurMe(e.target.value)} className={myClassName} /></span></Tooltip>);
    }
}

export default FieldEditorV2;
