import { Routes, Route } from "react-router-dom";


import Login from "./modules/login/login";
import Register from "./modules/account/register/register";
import Activate from "./modules/account/activate/activate";
import PasswordResetInit from "./modules/account/password-reset/init/password-reset-init";
import PasswordResetFinish from "./modules/account/password-reset/finish/password-reset-finish";
import Logout from "./modules/login/logout";
import ErrorBoundaryRoute from "./shared/error/error-boundary-route";
import PageNotFound from "./shared/error/page-not-found";

import Conventions from "./custom/convention/Conventions";
import Convention from "./custom/convention/Convention";
import Products from "./custom/widget/product/Products";
import Upload from "./custom/admin/upload/Upload";
import AdminTest from "./custom/admin/news/AdminTest";
import About from "./custom/about";
import Legal from "./custom/legal";
import Charte from "./custom/widget/Charte";
import News from "./custom/news";
import Contact from "./custom/contact";
import LinkStats from "./custom/admin/link-stats";
import Home from "./modules/home/Home";
import AdmLiquibase from "./custom/admin/AdmLiquibase";
import Geekis from "./custom/geeki/Geekis";
import Geeki from "./custom/geeki/Geeki";
import AdmCsv from "./custom/admin/AdmCsv";
import AdminGeekiEditDescription from "./custom/admin/geeki/AdminGeekiEditDescription";
import ConventionProposal from "./custom/convention/ConventionProposal";
import AdminNewsEdit from "./custom/admin/news/AdminNewsEdit";
import AdminNews from "./custom/admin/news/AdminNews";
import AdminGeeki from "./custom/admin/geeki/AdminGeeki";
import AdminGeekiEdit from "./custom/admin/geeki/AdminGeekiEdit";
import AdminActivityEdit from "./custom/admin/activity/AdminActivityEdit";
import AdminActivity from "./custom/admin/activity/AdminActivity";
import AdminPlaylistCandidate from "./custom/admin/playlistcandidate/AdminPlaylistCandidate";
import AdminConvention from "./custom/admin/convention/AdminConvention";
import AdminConventionEdit from "./custom/admin/convention/AdminConventionEdit";
import AdmDataQuality from "./custom/admin/AdmDataQuality";
import AdminPlaylist from "./custom/admin/playlist/AdminPlaylist";
import UnderConstruction from "./modules/underconstruction/UnderConstruction";


import CryptoPageV2 from "./custom/cryptool/v2/CryptoPageV2";


// tslint:disable:space-in-parens
/*const Account = Loadable({
  loader: () => import("./modules/account"),
  loading: () => <div>loading ...</div>
});

const Admin = Loadable({
  loader: () =>
    import("./modules/administration"),
  loading: () => <div>loading ...</div>
});*/
// tslint:enable


/*TODO CST access to admin: 
<PrivateRoute
        path="/admin"
        element={Admin}
        hasAnyAuthorities={[AUTHORITIES.ADMIN]} sessionHasBeenFetched={false} isAuthorized={false} index={true}      />
      <PrivateRoute
        path="/account"
        element={Account}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.USER,
          AUTHORITIES.GEEKI
        ]} sessionHasBeenFetched={false} isAuthorized={false} index={true}      />
      <PrivateRoute
        path="/entity"
        element={Entities}
        hasAnyAuthorities={[AUTHORITIES.ADMIN]} sessionHasBeenFetched={false} isAuthorized={false} index={true}      />
      
*/
const AppRoutes = () => (
  <div className="view-routes">
    <Routes>
      <Route path="/login" element={<ErrorBoundaryRoute path="/login" element={Login}/>} />
      <Route path="/logout" element={<ErrorBoundaryRoute path="/logout" element={Logout} />}/>
      <Route path="/register" element={<ErrorBoundaryRoute path="/register" element={Register} />}/>
      <Route path="/activate/:key?" element={<ErrorBoundaryRoute path="/activate/:key?" element={Activate} />}/>
      <Route path="/reset/request" element={<ErrorBoundaryRoute path="/reset/request" element={PasswordResetInit} />}/>
      <Route path="/reset/finish/:key?" element={<ErrorBoundaryRoute path="/reset/finish/:key?" element={PasswordResetFinish} />}/>
      <Route path="/apropos" element={<ErrorBoundaryRoute path="/apropos" element={About} />}/>
      <Route path="/legal" element={<ErrorBoundaryRoute path="/legal" element={Legal} />}/>
      <Route path="/news" element={<ErrorBoundaryRoute path="/news" element={News} />}/>
      <Route path="/charte" element={<ErrorBoundaryRoute path="/charte" element={Charte} />}/>
      <Route path="/contact" element={<ErrorBoundaryRoute path="/contact" element={Contact} />}/>
      <Route path="/conventions" element={<ErrorBoundaryRoute path="/conventions" element={Conventions} />}/>
      <Route path="/convention/:id" element={<ErrorBoundaryRoute path="/convention/:id" element={Convention} />}/>
      <Route path="/conventions/proposal" element={<ErrorBoundaryRoute path="/conventions/proposal" element={ConventionProposal} />}/>
      <Route path="/produits" element={<ErrorBoundaryRoute path="/produits" element={Products} />}/>
      <Route path="/boutique" element={<ErrorBoundaryRoute path="/boutique" element={Products} />}/>
      <Route path="/geekipedia" element={<ErrorBoundaryRoute path="/geekis" element={Geekis} />}/>
      <Route path="/geeki/:id" element={<ErrorBoundaryRoute path="/geeki/:id" element={Geeki} />}/>
      
      <Route path="/na" element={<ErrorBoundaryRoute path="/na" element={AdminActivityEdit}/>}/>

      <Route path="/ttc" element={<ErrorBoundaryRoute path="/ttc" element={CryptoPageV2}/>}/>

      
      
      <Route path="/admin/" element={<ErrorBoundaryRoute path="/" element={AdminGeeki} />}/>
      <Route path="/admin/upload" element={<ErrorBoundaryRoute path="/admin/upload" element={Upload} />}/>
      <Route path="/admin/liquibase" element={<ErrorBoundaryRoute path="/admin/liquibase" element={AdmLiquibase} />}/>
      <Route path="/admin/csv" element={<ErrorBoundaryRoute path="/admin/csv" element={AdmCsv} />}/>
      <Route path="/admin/dataquality" element={<ErrorBoundaryRoute path="/admin/dataquality" element={AdmDataQuality} />}/>
      
      <Route path="/admin/news/" element={<ErrorBoundaryRoute path="/admin/news/" element={AdminNews}/>}/>
      <Route path="/admin/news/create" element={<ErrorBoundaryRoute path="/admin/news/create" element={AdminNewsEdit} />}/>
      <Route path="/admin/news/edit/:id" element={<ErrorBoundaryRoute path="/admin/news/edit/:id" element={AdminNewsEdit} />}/>
      
      <Route path="/admin/geeki/" element={<ErrorBoundaryRoute path="/admin/geeki/" element={AdminGeeki}/>}/>
      <Route path="/admin/geeki/create" element={<ErrorBoundaryRoute path="/admin/geeki/create" element={AdminGeekiEdit} />}/>
      <Route path="/admin/geeki/edit/:id" element={<ErrorBoundaryRoute path="/admin/geeki/edit/:id" element={AdminGeekiEdit} />}/>
      <Route path="/admin/geeki/edit/description/:login" element={<ErrorBoundaryRoute path="/admin/geeki/edit/description/:login" element={AdminGeekiEditDescription} />}/>
      
      <Route path="/admin/activity/" element={<ErrorBoundaryRoute path="/admin/activity/" element={AdminActivity}/>}/>
      <Route path="/admin/activity/create" element={<ErrorBoundaryRoute path="/admin/activity/create" element={AdminActivityEdit} />}/>
      <Route path="/admin/activity/edit/:id" element={<ErrorBoundaryRoute path="/admin/activity/edit/:id" element={AdminActivityEdit} />}/>
      
      <Route path="/admin/playlistcandidate/" element={<ErrorBoundaryRoute path="/admin/playlistcandidate/" element={AdminPlaylistCandidate}/>}/>
      <Route path="/admin/playlist/" element={<ErrorBoundaryRoute path="/admin/playlist/" element={AdminPlaylist}/>}/>

      <Route path="/admin/convention/" element={<ErrorBoundaryRoute path="/admin/convention/" element={AdminConvention}/>}/>
      <Route path="/admin/convention/create" element={<ErrorBoundaryRoute path="/admin/convention/create" element={AdminConventionEdit} />}/>
      <Route path="/admin/convention/edit/:id" element={<ErrorBoundaryRoute path="/admin/convention/edit/:id" element={AdminConventionEdit} />}/>
      
 

      <Route path="/admin/test" element={<ErrorBoundaryRoute path="/admin/test" element={AdminTest} />}/>
      <Route path="/admin/link-stats" element={<ErrorBoundaryRoute path="/admin/link-stats" element={LinkStats} />}/>
      

  
      <Route path="/" element={<ErrorBoundaryRoute path="/" element={Home} />}/>
      <Route path="/PageNotFound" element={<ErrorBoundaryRoute element={PageNotFound} />}/>
    </Routes>
  </div>
);
//    <Route path="/" element={<ErrorBoundaryRoute path="/" element={Home} />}/>
//<Route path="/" element={<ErrorBoundaryRoute path="/" element={UnderConstruction} />}/>

export default AppRoutes;
