import { useState } from "react";
import "../../CryptoTable.css";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { porttcfolio } from "../../data/CryptoData";
import { updatePortfolioInBrowserCache } from "../../data/CryptoCache";
import { generateTtcId } from "../../data/CryptoDataType";
import { CryptoV2 } from "../data/CryptoDataTypeV2";
import CryptoRowCryptoV2 from "./CryptoRowCryptoV2";
import CryptoTableHeaderV2 from "./CryptoTableHeaderV2";

const CryptoTableV2 = (props: any) => {

  const [state, setState] = useState({
    order: 'asc',
    orderBy: 'symbol',
    updated: false,
    allExpanded:false,
    allCollapsed:false,
  });

  const collapseAll = () =>{
    setState({
      ...state, 
      allExpanded: false,
      allCollapsed:true
    });
  }

  const expandAll = () =>{
    setState({
      ...state, 
      allExpanded: true,
      allCollapsed: false
    });
  }

  const toggleUpdated = () => {
    setState({
      ...state, 
      updated: !state.updated,
    });
  }

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = state.orderBy === property && state.order === 'asc';
    let isAscStr = isAsc ? 'desc' : 'asc';
    if (isAscStr !== state.order) {
      setState({
        ...state,
        order: isAscStr,
      });
    }
    if (property !== state.orderBy) {
      setState({
        ...state,
        orderBy: property,
      });
    }
  }

  const onRemoveTarget = (ttcId: string, targetPos: number) => {
    let trouve = false;
    for (let i = 0; i < porttcfolio.cryptosV2.length; i++) {
      let cryptoV2 = porttcfolio.cryptosV2[i];
      for (let j = 0; j < cryptoV2.exchanges.length; j++) {
        let exc = cryptoV2.exchanges[j];
        if (exc.ttcId === ttcId) {
          exc.targets.splice(targetPos, 1);
          trouve = true;
          break;
        }

      }
    }
    if (trouve) {
      updatePortfolioInBrowserCache();
      props.partialRefresh(false);
    }
  }

  const onRemove = (ttcId: string) => {
    if (window.confirm("Etes vous sur de vouloir supprimer la ligne?")) {

      let posI = -1;
      let posJ = -1;
      for (let i = 0; i < porttcfolio.cryptosV2.length; i++) {
        let cryptoV2 = porttcfolio.cryptosV2[i];
        for (let j = 0; j < cryptoV2.exchanges.length; j++) {
          let exc = cryptoV2.exchanges[j];
          if (exc.ttcId === ttcId) {
            posJ = j;
            break;
          }
        }
        if (posJ >= 0) {
          cryptoV2.exchanges.splice(posJ, 1);
          if (cryptoV2.exchanges.length == 0) {
            posI = i;
            break;
          }
        }
      }
      if (posI >= 0) {
        porttcfolio.cryptosV2.splice(posI, 1);
        updatePortfolioInBrowserCache();
        props.partialRefresh(false);
      } else if (posJ >= 0) {
        updatePortfolioInBrowserCache();
        props.partialRefresh(false);
      }
    }
  }

  const onCryptoAdd = () => {
    let newCryptoV2: CryptoV2 = { symbol: '000', cryptoBagPrice: 0, exchanges: [], ttcId: generateTtcId() };
    porttcfolio.cryptosV2.unshift(newCryptoV2);
    updatePortfolioInBrowserCache();
    props.partialRefresh(false);
  }


  const partialRefresh = () => {
    props.partialRefresh(false);
  }


  function descendingComparator(a: any, b: any, orderBy: any) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  function getComparator(order: any, orderBy: any) {
    return order === 'desc'
      ? (a: any, b: any) => descendingComparator(a, b, orderBy)
      : (a: any, b: any) => -descendingComparator(a, b, orderBy)
  }

  function stableSort(array: any, comparator: any): CryptoV2[] {
    const stabilizedThis = array.map((el: any, index: any) => [el, index])
    stabilizedThis.sort((a: any, b: any) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) {
        return order
      }
      return a[1] - b[1]
    })
    let stabilizedMap = stabilizedThis.map((el: any) => el[0])
    return Array.from(stabilizedMap.values());
  }

  let sortedRow = stableSort(porttcfolio.cryptosV2, getComparator(state.order, state.orderBy));
  let arrayRows = [];
  for (let i = 0; i < sortedRow.length; i++) {
    let cryptoV2: CryptoV2;
    cryptoV2 = sortedRow[i] as CryptoV2;
    arrayRows.push(<CryptoRowCryptoV2 cryptoV2={cryptoV2}
      key={"key10-" + i + "-" + Math.random()}
      buyPopupRef={props.buyPopupRef} viewMode={props.viewMode} toggleUpdated={toggleUpdated}
      onRemoveTarget={onRemoveTarget} onRemove={onRemove} partialRefresh={partialRefresh}
      fullRefresh={props.fullRefresh} 
      expanded={state.allExpanded}
      collapsed={state.allCollapsed}/>);

  }

  return (porttcfolio.cryptosV2 ?
    <TableContainer component={Paper} sx={{ minHeight: 800 }} className="crypto-table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <CryptoTableHeaderV2
          viewMode={props.viewMode}
          order={state.order}
          orderBy={state.orderBy}
          onRequestSort={handleRequestSort}
          rowCount={porttcfolio.cryptosV2.length}
          onCryptoAdd={onCryptoAdd}
          partialRefresh={props.partialRefresh}
          fullRefresh={props.fullRefresh}
          collapseAll={collapseAll}
          expandAll={expandAll}
        />
        <TableBody>
          {arrayRows}
        </TableBody>
      </Table>
    </TableContainer> : <></>
  );
};

export default CryptoTableV2;
