import { forwardRef, useRef, useState } from "react";
import "./CryptoTable.css";
import "./Crypto.css";
import { porttcfolio } from "./data/CryptoData";
import CryptoHelp from "./CryptoHelp";
import HelpIcon from '@mui/icons-material/Help';
import { IconButton, Tooltip } from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CalculateIcon from '@mui/icons-material/Calculate';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import BubbleChartOutlinedIcon from '@mui/icons-material/BubbleChartOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';
import SearchIcon from '@mui/icons-material/Search';
import cryptoLogo from '../cryptoverview2.png';
import SettingsIcon from '@mui/icons-material/Settings';
import AdminDialog from "./admin/AdminDialog";
import GraphDialog from "./v2/graph/GraphDialog";
import CryptoHeaderSummary from "./CryptoHeaderSummary";
import CryptoHeaderSummaryDialog from "./CryptoHeaderSummaryDialog";
import UploadPortfolioDialog from "./UploadPortfolioDialog";
import CryptoHeaderGraphV2 from "./v2/graph/CryptoHeaderGraphV2";
import { hideBubbles, showBubbles } from "./v2/bubble/CryptoBubblesUtil";

const CryptoHeader = forwardRef(( props:any,ref:any  ) => {
  const helpRef = useRef(null);
  const adminRef = useRef(null);
  const graphRef = useRef(null);


  const toggleHelp = () => {
    if (helpRef.current != null) {
      (helpRef.current as any).toggleMe();
    }
  }

  const toggleEditMode: any = () => {
    hideBubbles();
    let appHeader = document.getElementById("App-header");
    if (appHeader != null) {
      appHeader.classList.toggle("viewMode");
    }
    props.toggleViewMode();
  }



  const doAdmin = () => {
    if (adminRef.current) {
      (adminRef.current as any).handleOpen();
    }
  }

  const doGraph = () => {
    if (graphRef.current) {
      (graphRef.current as any).handleOpen();
    }
  }

  const [updated, setUpdated] = useState(true);

  const forceRefresh = () =>{
    setUpdated(!updated);
  }

  const summary1Ref = useRef(null);
  const doDetails = () => {
    if (summary1Ref.current) {
      (summary1Ref.current as any).handleOpen();
    }
  }
  const uploadPortfolioRef = useRef(null);
  const doUploadPortfolio = () => {
    if (uploadPortfolioRef.current) {
      (uploadPortfolioRef.current as any).handleOpen();
    }
  }  

  return (
    <div className="crypto-header">
      <div className="crypto-header-buttons">
        <span className="crypto-title"><img src={cryptoLogo} alt="Cryptoverview"
        className="cryptoLogo" height="50" width="50" />RYPTOVERVIEW</span>
        <span>

          <Tooltip title="Rafraichir">
            <IconButton onClick={props.fullRefresh}>
              <ReplayCircleFilledIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Graphiques">
              <IconButton onClick={doGraph}>
                <BarChartIcon />
              </IconButton>
            </Tooltip>

          <Tooltip title="Résumé financier">
              <IconButton onClick={doDetails}>
                <CalculateIcon />
              </IconButton>
            </Tooltip>

        

          <Tooltip title="Afficher les bulles">
            <IconButton onClick={showBubbles}>
              <BubbleChartOutlinedIcon />
            </IconButton>
          </Tooltip>

          <button className="ttcButton" onClick={toggleEditMode}>{props.viewMode ? "Editer tableau" : "Fin édition"}</button>

          <Tooltip title="Uploader portefeuille">
            <IconButton onClick={doUploadPortfolio}>
              <FileUploadIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Downloader portefeuille">
            <IconButton onClick={downloadData}>
              <DownloadForOfflineIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Administration">
            <IconButton onClick={doAdmin}>
              <SettingsIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Aide">
            <IconButton onClick={toggleHelp}>
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </span>
        <span className="crypto-header-search">
          <Tooltip title="Rechercher une crypto-monnaie">
            <SearchIcon />
          </Tooltip>:&nbsp;
          <input
            type="text"
            onChange={props.handleChange}
            placeholder="Exemple: Bitcoin ou BTC"
          />
        </span>
      </div>
      <div className="crypto-header-sub">
        <CryptoHeaderSummary viewMode={props.viewMode} impotsPerc={props.impotsPerc}/>        
        <CryptoHeaderGraphV2 investment={porttcfolio.investment} />
      </div>
      <CryptoHelp open={false} ref={helpRef} />
      <AdminDialog ref={adminRef} />
      <GraphDialog ref={graphRef} />
      <CryptoHeaderSummaryDialog ref={summary1Ref}
        impotsPerc={props.impotsPerc}
        viewMode={props.viewMode}/>
        <UploadPortfolioDialog ref={uploadPortfolioRef}
        fullRefresh={props.fullRefresh}/>
    </div>
  );
});

const downloadData = () => {
  let value = JSON.stringify(porttcfolio);
  const blob = new Blob([value], { type: 'application/json' });

  const a = document.createElement('a');
  a.download = 'portefeuille.json';
  a.href = URL.createObjectURL(blob);
  a.addEventListener('click', (e) => {
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  });
  a.click();

}

export default CryptoHeader;