import { MenuItem, Select } from "@mui/material";
import React from "react";
import { CryptoExchangeV2, CryptoV2 } from "../data/CryptoDataTypeV2";
import { StatusEnum } from "../../data/CryptoDataType";
import { reprocessTargetsData } from "../../data/CryptoProcessData";
import { updatePortfolioInBrowserCache } from "../../data/CryptoCache";

interface IProps {
  targetPos: any;
  cryptoV2: CryptoV2;
  cryptoExchangeV2: CryptoExchangeV2;
}
interface IState {
  value: StatusEnum;
}


class StatusEditorV2 extends React.Component<IProps, IState> {

  constructor(props: any) {
    super(props);
    let initValue = this.props.cryptoExchangeV2.targets[this.props.targetPos]["status"] == null ? StatusEnum.Init : (this.props.cryptoExchangeV2.targets[this.props.targetPos]["status"] ? this.props.cryptoExchangeV2.targets[this.props.targetPos]["status"] : StatusEnum.Init);
    this.state = {
      value: (initValue===undefined?StatusEnum.Init:initValue)
    };
  }

  handleChange = (event:any) => {
    let newValue = Number((event.target as HTMLInputElement).value);
    let cryptoExchangeV2Target=this.props.cryptoExchangeV2.targets[this.props.targetPos];

    cryptoExchangeV2Target["status"] = newValue;

    if(StatusEnum.Order || StatusEnum.Sold){
      this.props.cryptoExchangeV2.automaticUpdate=false;// we must keep the bag value, must not be updated buy job
    }

    reprocessTargetsData(this.props.cryptoV2,this.props.cryptoExchangeV2);
    updatePortfolioInBrowserCache();
    this.setState({ value: newValue });
  }


  render() {
    let targetData = this.props.cryptoExchangeV2.targets[this.props.targetPos]["status"];

    return (<Select
      className="statusEditor2"
      labelId={`select-label-${this.props.cryptoExchangeV2.ttcId}-${this.props.targetPos}`}
      value={targetData}
      onChange={this.handleChange}
    >
      <MenuItem value={StatusEnum.Init}>Initial</MenuItem>
      <MenuItem value={StatusEnum.Order}>Ordre de vente</MenuItem>
      <MenuItem value={StatusEnum.Sold}>Vendu</MenuItem>
    </Select>);
  }
}

export default StatusEditorV2;