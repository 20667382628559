import { Dialog, DialogActions, DialogContent, DialogTitle, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import { Table } from "reactstrap";
import { porttcfolio } from "./data/CryptoData";
import FieldEditorV2 from "./v2/editor/FieldEditorV2";

const CryptoHeaderSummaryDialog = forwardRef((props: any, ref) => {

    const [open, setOpen] = useState(false);

    const updatePorttcfolioInvestment = () => { }

    useImperativeHandle(ref, () => ({
        handleOpen() {
            setOpen(true);
        },
        handleClose() {
            setOpen(false);
        }
    }));

    const handleApply = () => {
        setOpen(false);
    };

    let toto = 0;

    let cryptosSummary: any = [];
    if (porttcfolio.transient?.cryptoSummary) {
        for (let i = 0; i < porttcfolio.transient?.cryptoSummary.length; i++) {
            let summary = porttcfolio.transient?.cryptoSummary[i];
            cryptosSummary.push(<tr><td>{summary.symbol}</td><td>{summary.totalLeft.toFixed(0)}</td><td>{summary.sold.toFixed(0)}</td><td>{summary.reinvested.toFixed(0)}</td></tr>);
            toto += summary.totalLeft;
        }
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
            <DialogTitle>Résumé financier</DialogTitle>
            <DialogContent>
                <TableContainer component={Paper}>
                    <form id="adminForm">
                        <Table className="crypto-summary-dialog">
                            <tr>
                                <td>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell>Montant USDT</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <tr><th>Capital BRUT:</th><td>{porttcfolio.transient?.totalBeforeTax?.toFixed(0)}</td></tr>
                                            <tr><th>Impots:</th><td>{porttcfolio.transient?.totalTax?.toFixed(0)}</td></tr>
                                            <tr><th>Capital NET:</th><td>{porttcfolio.transient?.totalAfterTax?.toFixed(0)}</td></tr>
                                            <tr><th>&nbsp;</th></tr>
                                            <tr><th>Montant déposé:</th><td><FieldEditorV2
                                                className="fixedWidth"
                                                tooltip="Montant déposé" label=""
                                                object={porttcfolio} objectKey="investment"
                                                onChange={updatePorttcfolioInvestment}
                                                viewMode={false}
                                                type="number" key={"key10" + Math.random()} /></td></tr>
                                            <tr><th>Gains:</th><td>{porttcfolio.transient?.totalGains?.toFixed(0)}</td></tr>
                                            <tr><th>&nbsp;</th></tr>
                                            <tr><th>Montant des ventes:</th><td>{porttcfolio.transient?.totalSold?.toFixed(0)}</td></tr>
                                            <tr><th>Montant réinvesti:</th><td>{porttcfolio.transient?.totalReInvested?.toFixed(0)}</td></tr>
                                            <tr><th>Montant ventes après réinvestissement:</th><td>{porttcfolio.transient?.totalSoldMinusReinvested?.toFixed(0)}</td></tr>
                                        </TableBody>
                                    </Table>
                                </td>
                                <td>
                                    <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Crypto</TableCell>
                                                    <TableCell>Capital actuel restant</TableCell>
                                                    <TableCell>Vente</TableCell>
                                                    <TableCell>Réinvestissement</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {cryptosSummary}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </td>
                            </tr>
                        </Table>


                    </form>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <button onClick={() => setOpen(false)} className="ttcButton">
                    Annuler
                </button>
                <button onClick={handleApply} className="ttcButton">
                    Appliquer
                </button>
            </DialogActions>
        </Dialog>
    );
});

export default CryptoHeaderSummaryDialog;