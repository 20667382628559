import { CryptoExchangeV2, CryptoV2 } from "../v2/data/CryptoDataTypeV2";

export const isStableCoin = (symbol: string) => {
  if (symbol == null || symbol === undefined) {
    return false;
  } else{
    return (symbol.toUpperCase() === 'USDT' || symbol.toUpperCase() === 'USDC');
  }
}

export const generateTtcId = () => {
  return '' + (new Date()).getTime() + '-' + Math.random();
}

export interface CryptoTableRow {//TODO CST deprecated
  ttcId?: string,
  name?: string,
  site: string,
  image?: any,
  symbol: string,
  bag: number,
  bagPrice?: number | null,
  unitPrice?: number,
  invested?: number;
  price_change_percentage_24h?: number,
  targets: TargetData[],
  comment?: string | null,
  coingeckoId?: string | null,
  history?: string,
  market_cap?: number,
  market_cap_rank?: number,
  ath?: number,
  loaded: boolean,
  automaticUpdate:boolean,
  updatedFromExchange:boolean
}

export interface TargetData {//TODO CST deprecated
  rate: number,
  price?: number,
  sold?: boolean | null,
  bag?: number,
  bagPerc?: number,
  perc?: number | null,
  status?: StatusEnum,
  graphProcessed?: boolean,
  reinvestedAmount?:number
}




export enum StatusEnum {
  Init = 0,
  Order = 1,
  Sold = 2
}

