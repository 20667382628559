import { Box, TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { AddCircle } from "@mui/icons-material";
import { useState } from "react";

function CryptoTableHeaderV2(props: any) {

  const [state, setState] = useState({ allExpanded: false });
  const doCollapseAll = () => {
    props.collapseAll();
    setState({ ...state, allExpanded: false });
}

const doExpandAll = () => {
  props.expandAll();
    setState({ ...state, allExpanded: true });
}


  const {
    order,
    orderBy,
    onRequestSort,
  } = props

  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property)
  }


  return (<TableHead>
    <TableRow>
      {(!props.viewMode) &&
        <TableCell align="center" padding={'normal'} className="addRow percLeft">
          <Tooltip title="Ajouter ligne"><IconButton onClick={props.onCryptoAdd}>
            <AddCircle />
          </IconButton></Tooltip>
        </TableCell>}
      <TableCell
        key={"symbol"}
        colSpan={2}
        align="center"
        padding={'normal'}
        className="percLeft"
        sortDirection={orderBy === "symbol" ? order : false}
      >
        <TableSortLabel
          direction={orderBy === "symbol" ? order : 'asc'}
          onClick={createSortHandler("symbol")}
        >
          Crypto-monnaie
          {orderBy === "symbol" ? (
            <Box component="span">
            </Box>
          ) : null}
        </TableSortLabel>
      </TableCell>
      {(!props.viewMode) && <TableCell colSpan={2} align="center" padding={'normal'} className="percLeft"></TableCell>}
      {props.viewMode && <TableCell  align="center" padding={'normal'} className="percLeft">Tendance</TableCell>}
      {props.viewMode && <TableCell align="center" padding={'normal'} className="percLeft">Capital</TableCell>}


      <TableCell rowSpan={2} align="right" className="percLeft crypto-row-expand-collapse">
                {state.allExpanded && <Tooltip title="Minimiser tous"><IconButton onClick={doCollapseAll}>
                    <UnfoldLessIcon />
                </IconButton></Tooltip>}
                {(!state.allExpanded) && <Tooltip title="Maximiser tous"><IconButton onClick={doExpandAll}>
                    <UnfoldMoreIcon />
                </IconButton></Tooltip>}

            </TableCell>
      </TableRow>

    <TableRow>
      {(!props.viewMode) &&
        <TableCell align="center" padding={'normal'} className="addRow percLeft">
        </TableCell>}
      <TableCell
        key={"symbol"}
        align="center"
        colSpan={2}
        padding={'normal'}
        className="percLeft"
        sortDirection={orderBy === "symbol" ? order : false}
      >
       Exchange
      </TableCell>
      <TableCell
      colSpan={2} 
        key={"target1Perc"}
        className="crypto-row-targets-v2 percLeft"
        align="center"
        padding={'normal'}
        sortDirection={orderBy === "target1Perc" ? order : false}
      >
        <TableSortLabel
          direction={orderBy === "target1Perc" ? order : 'desc'}
          onClick={createSortHandler("target1Perc")}
        >
          Objectifs
          {orderBy === "target1Perc" ? (
            <Box component="span">
            </Box>
          ) : null}
        </TableSortLabel>
      </TableCell>
    </TableRow>

  </TableHead>);
}


export default CryptoTableHeaderV2;