import geekiExchangesRequest, { geekiExchangesDefRequest } from "./exchanges";
import { updatePortfolioInBrowserCache } from "./CryptoCache";
import { StatusEnum, generateTtcId } from "./CryptoDataType";
import { loadPricesV2 } from "./CryptoQuery";
import { calculPrice, initTransientDataV2, porttcfolio } from "./CryptoData";
import { CryptoExchangeV2, CryptoV2, Exchange, ExchangeDef, TargetDataV2 } from "../v2/data/CryptoDataTypeV2";


export const sortPorttcfolioV2 = () => {
  let obj = porttcfolio.cryptosV2;
  obj.sort((a: any, b: any) => (a.symbol.toUpperCase() > b.symbol.toUpperCase()) ? 1 : ((b.symbol.toUpperCase() > a.symbol.toUpperCase()) ? -1 : 0))
  updatePortfolioInBrowserCache();
}

export const initPorttcfolioV2 = (headerRef: any) => {
  if (porttcfolio.exchanges) {
    geekiExchangesRequest(porttcfolio.exchanges).then(response => {
      let data = (response as any).data;
      for (let i = 0; i < data.result.length; i++) {
        updateExchangeDataV2(data.result[i].exchange, data.result[i].symbol, data.result[i].bag);
        //TODO CST manage removed
      }
      initPorttcfolioStep2V2(headerRef);
    })
      .catch(error => {
        console.error('Une erreur est survenue', error);
      });
  } else {
    porttcfolio.exchanges = [];
    initPorttcfolioStep2V2(headerRef);
  }
}


const initPorttcfolioStep2V2 = (headerRef: any) => {
  if (!porttcfolio.impotsPerc) {
    porttcfolio.impotsPerc = 33.0;
  }

  if (porttcfolio.exchanges) {
    porttcfolio.exchanges.sort((a: Exchange, b: Exchange) => a.exchange.toUpperCase().localeCompare(b.exchange.toUpperCase()))
  }

  loadPricesV2();
  let exchanges: ExchangeDef[] = [];
  for (let i = 0; i < porttcfolio.cryptosV2.length; i++) {
    let cryptosV2 = porttcfolio.cryptosV2[i];
    for (let j = 0; j < cryptosV2.exchanges.length; j++) {
      let exc = cryptosV2.exchanges[j];
      for (let k = 0; k < exc.targets.length; k++) {
        if (exc.targets[k].status == null || exc.targets[k].status === undefined) {
          exc.targets[k].status = StatusEnum.Init;
        }
      }
      reprocessTargetsData(cryptosV2, exc);

      let trouve = false;
      for (let exchange of exchanges) {
        if (exchange.name.toUpperCase() === exc.name.toUpperCase()) {
          trouve = true;
          break;
        }
      }
      if (!trouve) {
        exchanges.push({ name: exc.name.toUpperCase() });
      }
    }
  }



  geekiExchangesDefRequest(exchanges);
  sortPorttcfolioV2();
  initTransientDataV2(headerRef);
}


const updateExchangeDataV2 = (exchangeName: string, symbol: string, bag: number) => {
  let trouve = false;
  for (let i = 0; i < porttcfolio.cryptosV2.length; i++) {
    let cryptoV2 = porttcfolio.cryptosV2[i];
    for (let j = 0; j < cryptoV2.exchanges.length; j++) {
      let cryptoExchangeV2 = cryptoV2.exchanges[j];
      if (cryptoV2.symbol.trim() === symbol.trim()) {
        if (cryptoExchangeV2.automaticUpdate && cryptoExchangeV2.bag !== bag) {
          if (Number(cryptoExchangeV2.bag) !== Number(bag)) {
            cryptoExchangeV2.updatedFromExchange = true;
          }

          cryptoExchangeV2.bag = Number(bag);
          updatePortfolioInBrowserCache();
          console.log("UPDATED AUTOMATIC:" + symbol + " - " + cryptoExchangeV2.bag + " / " + bag);
        }

        trouve = true;
      }
    }
  }

  if ((!trouve)) {
    console.log("NON TROUVE [" + symbol + "]");
    let newCryptoV2: CryptoV2 = {
      ttcId: generateTtcId(), symbol: symbol,
      cryptoBagPrice: 0,
      exchanges: []
    };
    let newCryptoExchangeV2: CryptoExchangeV2 = {
      ttcId: generateTtcId(),
      name: exchangeName,
      bag: Number(bag),
      exchangeBagPrice: 0,
      automaticUpdate: true,
      updatedFromExchange: true,
      targets: []
    };
    newCryptoV2.exchanges.push(newCryptoExchangeV2);
    porttcfolio.cryptosV2.push(newCryptoV2);
    updatePortfolioInBrowserCache();
  }
}

export const filterPorttcfolioV2 = (coingeckoData: any) => {
  return porttcfolio.cryptosV2;
  /*

  let target: any[];
  target = [];
  let portfolioCryptosV2 = porttcfolio.cryptosV2;
  if (coingeckoData.length === 0) {
    return portfolioCryptosV2;
  }
  for (let i = 0; i < portfolioCryptosV2.length; i++) {
    let portfolioCryptoV2: CryptoV2 = { ...portfolioCryptosV2[i] };
    let trouve = false;
    for (let j = 0; j < coingeckoData.length; j++) {
      let coingeckoDataRow = coingeckoData[j];

      if (coingeckoDataRow.symbol.toLowerCase() === portfolioCryptoV2.symbol.toLowerCase()
      ) {
        for (let k=0;k<portfolioCryptoV2.exchanges.length;k++){
          let newData: CryptoTableRow =
          {
            ttcId: (portfolioItem.ttcId != null && portfolioItem.ttcId !== undefined) ? portfolioItem.ttcId : generateTtcId(),
            symbol: coingeckoDataRow.symbol,
            name: coingeckoDataRow.name,
         ...
            automaticUpdate: coingeckoDataRow.automaticUpdate,
            updatedFromExchange: portfolioItem.updatedFromExchange
          }
          target.push(newData);
          trouve = true;
       //   break;
        }
        
      }
    }
    if (!trouve) {
      let coingeckoId = portfolioItem.coingeckoId == null || portfolioItem.coingeckoId === undefined ? null : portfolioItem.coingeckoId;
      let cryptoPrice = getCryptoExpiration(portfolioItem.symbol, portfolioItem.name === undefined ? '' : portfolioItem.name
        , coingeckoId);
      if (cryptoPrice != null) {
        let newData: CryptoTableRow =
        {
          ttcId: (portfolioItem.ttcId != null && portfolioItem.ttcId !== undefined) ? portfolioItem.ttcId : generateTtcId(),
          symbol: portfolioItem.symbol,
          name: portfolioItem.name !== undefined ? portfolioItem.name : portfolioItem.symbol,
          image: portfolioItem.image ? portfolioItem.image : cryptoPrice.image,
        ...
          automaticUpdate: portfolioItem.automaticUpdate,
          updatedFromExchange: portfolioItem.updatedFromExchange
        }

        target.push(newData);
        trouve = true;
      }
    }
    if (!trouve) {
      let currentPrice = 0;
      if (portfolioItem.unitPrice !== undefined) {
        currentPrice = portfolioItem.unitPrice;
      }
      let coingeckoId = null;
      if (portfolioItem.coingeckoId !== undefined && portfolioItem.coingeckoId != null) {
        coingeckoId = portfolioItem.coingeckoId;
      }

      let newData: CryptoTableRow =
      {
        ttcId: (portfolioItem.ttcId != null && portfolioItem.ttcId !== undefined) ? portfolioItem.ttcId : generateTtcId(),
        symbol: portfolioItem.symbol,
        name: portfolioItem.name !== undefined ? portfolioItem.name : portfolioItem.symbol,
        image: portfolioItem.image ? portfolioItem.image : '',
        ...
        automaticUpdate: portfolioItem.automaticUpdate,
        updatedFromExchange: portfolioItem.updatedFromExchange
      }

      target.push(newData);
    }
  }

  return target;
  */
}

export const processTargetData = (srcData: any, filter: any) => {
  let result: TargetDataV2[] = [];
  let targetData: TargetDataV2[] = filter.targets;

  if (targetData) {
    for (let i = 0; i < targetData.length; i++) {
      let src: TargetDataV2 = targetData[i];
      let rate: number | null = (src.rate === undefined ? null : src.rate);
      if (src.bagPerc === undefined || src.bagPerc === null) {
        src.bagPerc = 20;
      } else if (typeof src.bagPerc === "string") {
        src.bagPerc = parseInt(src.bagPerc);
      }

      if (typeof src.rate === "string") {
        src.rate = parseFloat(src.rate);
      }

      let bagOfTarget = filter.bag * src.bagPerc / 100;
      result.push({
        rate: src.rate,
        perc: src.rate == null ? null : (srcData.current_price ? (srcData.current_price * 100 / src.rate) : (srcData.unitPrice * 100 / src.rate)),
        price: calculPrice(bagOfTarget, rate),
        bag: bagOfTarget,
        bagPerc: src.bagPerc,
        status: src.status,
        graphProcessed: false,
        reinvestedAmount: src.reinvestedAmount
      });
    }
  }

  return result;
}

export const reprocessTargetsData = (cryptoV2: CryptoV2, cryptoExchangeV2: CryptoExchangeV2) => {
  if (cryptoExchangeV2.targets) {
    for (let i = 0; i < cryptoExchangeV2.targets.length; i++) {
      let target = cryptoExchangeV2.targets[i];
      let rate: number | null = (target.rate === undefined ? null : target.rate);
      let unitPrice = (cryptoV2.unitPrice == null || cryptoV2.unitPrice === undefined) ? 0 : cryptoV2.unitPrice;

      if (target.bagPerc === undefined || target.bagPerc === null) {
        target.bagPerc = 20;
      }

      let bagOfTarget = cryptoExchangeV2.bag * target.bagPerc / 100;


      target.bag = bagOfTarget;
      target.price = calculPrice(bagOfTarget, rate);
      target.perc = (rate == null || unitPrice === undefined) ? null : (unitPrice * 100 / rate);
    }

  }
}

