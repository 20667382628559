import { forwardRef, useRef } from "react";
import { porttcfolio } from "./data/CryptoData";
import CryptoHeaderSummaryDialog from "./CryptoHeaderSummaryDialog";

const CryptoHeaderSummary = forwardRef((props: any, ref: any) => {
    const summaryRef = useRef(null);

    const doDetails = () => {
        if (summaryRef.current) {
            (summaryRef.current as any).handleOpen();
        }
    }

    return <span className="crypto-summary left">
        <CryptoHeaderSummaryDialog ref={summaryRef}
            impotsPerc={props.impotsPerc}
            viewMode={props.viewMode} />

        <br />
        {porttcfolio.cryptosV2.length>0 && <table>
            <tbody>
                <tr><td>Actuel:</td><td>{porttcfolio.transient?.totalBeforeTax?.toFixed(0)} USDT</td></tr>
                <tr><td colSpan={2}> <button onClick={doDetails} className="ttcButton"> details</button></td></tr>
            </tbody>
        </table>}
    </span>;
});

export default CryptoHeaderSummary;