import { useState, forwardRef, useImperativeHandle } from 'react';
import "../../Crypto.css";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TableContainer,
    Paper,
} from '@mui/material';
import CryptoMultiBarPerSymbolV2 from './CryptoMultiBarPerSymbolV2';
import CryptoPiePerSymbolCurrentV2 from './CryptoPiePerSymbolCurrentV2';
import CryptoPiePerSymbolTargetV2 from './CryptoPiePerSymbolTargetV2';
import CryptoPiePerExchangeV2 from './CryptoPiePerExchangeV2';



// Utilisation de forwardRef pour exposer des méthodes via ref
const GraphDialog = forwardRef((props, ref) => {

    const [open, setOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('option1');


    useImperativeHandle(ref, () => ({
        handleOpen() {
            setOpen(true);
        },
        handleClose() {
            setOpen(false);
        }
    }));

    const renderContent = () => {
        switch (selectedOption) {
            case 'option1':
                return <CryptoMultiBarPerSymbolV2 />;
            case 'option2':
                return <CryptoPiePerSymbolCurrentV2 />;
            case 'option3':
                return <CryptoPiePerSymbolTargetV2 />;
            case 'option4':
                return <CryptoPiePerExchangeV2 />;
            default:
                return <div>📝 **Sélectionnez une option dans le menu.**</div>;
        }
    };

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth={false}
            className="graphDialog">
            <DialogTitle>Graphiques <button className="ttcButton" onClick={() => setSelectedOption('option1')}>Actuel vs Objectifs</button>
                        <button className="ttcButton" onClick={() => setSelectedOption('option2')}>Actuel</button>
                        <button className="ttcButton" onClick={() => setSelectedOption('option3')}>Objectif</button>
                        <button className="ttcButton" onClick={() => setSelectedOption('option4')}>Exchanges</button></DialogTitle>
            <DialogContent>
            <TableContainer component={Paper} style={{ height: '100%', padding: '0px' }}>
                        {renderContent()}
                    </TableContainer>
            </DialogContent>
            <DialogActions>
                <button onClick={() => setOpen(false)} className="ttcButton">
                    Fermer
                </button>
            </DialogActions>
        </Dialog>
    );
});

export default GraphDialog;
