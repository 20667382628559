import "../../CryptoTable.css";
import { Tooltip } from "@mui/material";
import { StatusEnum } from "../../data/CryptoDataType";
import { CryptoV2, TargetDataV2 } from "../data/CryptoDataTypeV2";
import { getTargetColor } from "../../data/CryptoConfig";


const CryptoRowCryptoV2TargetsSummary = (props: any) => {

    let cryptoV2: CryptoV2 = props.cryptoV2;
    let target1 = null;
    for (let exchange of cryptoV2.exchanges) {
        for (let i = 0; i < exchange.targets.length; i++) {
            let target = exchange.targets[i];
            if (target.status !== StatusEnum.Sold) {
                let perc = (target1 == null ? null : (target1 as TargetDataV2).perc);
                let target1Perc = (perc == null ? 0 : perc)
                if (target1 == null || (target.perc != null && (target1Perc > target.perc))) {
                    target1 = target;
                }
                break;
            }
        }
    }
    let background = getTargetColor((target1 != null && target1.perc != null)?target1.perc:-1,"0.5");
    let tooltip = "Il n'y a pas d'objectif à atteindre";
    let perc="";
    if (target1 != null && target1.perc != null) {
        if (target1.perc >= 80) {
            tooltip = "Le prochain objectif est atteint à plus de 80%.";
        } else if (target1.perc >= 50) {
            tooltip = "Le prochain objectif est atteint à plus de 50%.";
        } else {
            tooltip = "Le prochain objectif est atteint à moins de 50%.";
        }
        perc=target1.perc.toFixed(2)+"%";
    }

    return (<Tooltip title={tooltip}><div
        style={{ background: background }}>
        {perc}
    </div></Tooltip>);

}

export default CryptoRowCryptoV2TargetsSummary;