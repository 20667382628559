import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import { porttcfolio, reloadPorttcfolioFromJsonV2 } from "./data/CryptoData";
import HelpIcon from '@mui/icons-material/Help';
import { Table } from "reactstrap";

const UploadPortfolioDialog = forwardRef((props: any, ref) => {

  const [updated, setUpdated] = useState(true);

  const [state, setState] = useState({
    open: false,
    text: '',
  });

  const updatePorttcfolioInvestment = () => { }

  useImperativeHandle(ref, () => ({
    handleOpen() {
      let textareaValue = JSON.stringify(porttcfolio);
      setState({ text: textareaValue, open: true });
    },
    handleClose() {
      setState({ ...state, open: false });
    }
  }));

  const openCheckData = () => {
    window.open("https://jsonlint.com/");
  }

  const handleApply = () => {
    let textarea = document.getElementById("uploadPortfolioEditor") as HTMLTextAreaElement;
    localStorage.setItem("cryptool", textarea.value);

    reloadPorttcfolioFromJsonV2(textarea.value, null);

    props.fullRefresh();
    setState({ ...state, open: false });
  };

  let toto = 0;

  let cryptosSummary: any = [];
  if (porttcfolio.transient?.cryptoSummary) {
    for (let i = 0; i < porttcfolio.transient?.cryptoSummary.length; i++) {
      let summary = porttcfolio.transient?.cryptoSummary[i];
      cryptosSummary.push(<tr><td>{summary.symbol}</td><td>{summary.totalLeft.toFixed(0)}</td><td>{summary.sold.toFixed(0)}</td><td>{summary.reinvested.toFixed(0)}</td></tr>);
      toto += summary.totalLeft;
    }
  }

  let textareaValue = JSON.stringify(porttcfolio);


  const handleCopy = () => {
    navigator.clipboard.writeText(state.text)
      .then(() => alert('Texte copié dans le presse-papier !'))
      .catch(err => console.error('Erreur lors de la copie :', err));
  };

  return (
    <Dialog open={state.open} onClose={() => setState({ ...state, open: false })} fullWidth maxWidth="md">
      <DialogTitle>Uploader Portefeuille</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <form id="adminForm">
            <Table className="crypto-summary-dialog">
              <tr>
                <td>
                  <div className="upload-portfolio" >
                    <br />
                    <textarea id="uploadPortfolioEditor"
                      onChange={(e) => setState({ ...state, text: e.target.value })}
                      cols={100} rows={20}>{textareaValue}</textarea>
                    <br />
                    <button onClick={openCheckData} className="ttcButton">Vérifier json</button>
                    <button onClick={handleCopy} className="ttcButton">Copier</button>

                  </div>
                </td></tr></Table>

          </form>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <button onClick={() => setState({ ...state, open: false })} className="ttcButton">
          Annuler
        </button>
        <button onClick={handleApply} className="ttcButton">
          Appliquer
        </button>
      </DialogActions>
    </Dialog>
  );
});

export default UploadPortfolioDialog;