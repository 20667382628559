import { porttcfolio } from "../../data/CryptoData";

export const pieColors = [
    '#8dd3c7',
    '#ffffb3',
    '#bebada',
    '#fb8072',
    '#80b1d3',
    '#fdb462',
    '#b3de69',
    '#fccde5',
    '#d9d9d9',
    '#bc80bd',
    '#ccebc5',
    '#ffed6f',
    'orange'
  ];

export const sumOrNull = (a: number | null | undefined, b: number | null | undefined) => {
    return ((a == null || a === undefined || isNaN(a))
        ? ((b == null || b === undefined || isNaN(b)) ? 0 : b)
        : (b == null || b === undefined || isNaN(b)) ? a : a + b);
}

export const valueFormatter = (value: number) => `$${value}`;

export const calculBarChartData = (arcLabel: any, arcLabelTarget: any, arcLabelExchange: any) => {
    if (porttcfolio.transient != null) {
        for (let i = 0; i < porttcfolio.transient.exchanges.length; i++) {
            let ct = porttcfolio.transient.exchanges[i];
            arcLabelExchange.push({ value: ct.value, label: ct.label2 });
        }
        for (let i = 0; i < porttcfolio.transient.cryptos.length; i++) {
            let ct = porttcfolio.transient.cryptos[i];
            arcLabel.push({ value: ct.value, label: ct.label2 });
        }
        for (let i = 0; i < porttcfolio.transient.cryptoTargets.length; i++) {
            let ct = porttcfolio.transient.cryptoTargets[i];
            arcLabelTarget.push({ value: ct.value, label: ct.label2 });
        }
    }
}

export const calculBarChartDataCurrent = (arcLabel: any) => {
    if (porttcfolio.transient != null) {
        for (let i = 0; i < porttcfolio.transient.cryptos.length; i++) {
            let ct = porttcfolio.transient.cryptos[i];
            arcLabel.push({ value: ct.value, label: ct.label2 });
        }
    }
}

export const calculBarChartDataTarget = (arcLabelTarget: any) => {
    if (porttcfolio.transient != null) {
        for (let i = 0; i < porttcfolio.transient.cryptoTargets.length; i++) {
            let ct = porttcfolio.transient.cryptoTargets[i];
            arcLabelTarget.push({ value: ct.value, label: ct.label2 });
        }
    }
}

export const calculBarChartDataExchange = (arcLabelExchange: any) => {
    if (porttcfolio.transient != null) {
        for (let i = 0; i < porttcfolio.transient.exchanges.length; i++) {
            let ct = porttcfolio.transient.exchanges[i];
            arcLabelExchange.push({ value: ct.value, label: ct.label2 });
        }
    }
}