import React from "react";
import "../../CryptoTable.css";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts";
import { Row, Col, Table } from "reactstrap";
import { calculBarChartDataCurrent, pieColors } from "./CryptoGraphUtils";
import { porttcfolio } from "../../data/CryptoData";
import { TableBody } from "@mui/material";
import { CryptoTotal } from "../data/CryptoDataTypeV2";

interface ChildProps {
}

let arcLabel: any = [];


const getArcLabel = (params: any) => {
    if (arcLabel != null ) {
        let TOTAL = 0;
        for (let item of arcLabel) {
            TOTAL = TOTAL + item.value;
        }
        return `${params.label2}`;
    } else {
        return '';
    }
};

const renderPie = (data: any, target: boolean) => {
    return <PieChart
        colors={pieColors}
        slotProps={{
            legend: {
                padding: 0,
            },
        }}
        sx={{
            [`& .${pieArcLabelClasses.root}`]: {
                fill: 'black',
                fontSize: '14px',
                fontWeight: 'bold'
            },
        }}
        series={[
            {
                outerRadius: 380,
                innerRadius: 30,
                paddingAngle: 1,
                cornerRadius: 5,
                arcLabelRadius: 370,
                arcLabel: (getArcLabel),
                cx: 500,
                data
            },
        ]}
        width={1300}
        height={800}
    />;
}

const CryptoPiePerSymbolCurrentV2: React.FC<ChildProps> = (props: ChildProps) => {

    arcLabel = [];
    calculBarChartDataCurrent(arcLabel);

    let sortedItemsCryptos: CryptoTotal[] = (porttcfolio.transient == null) ? [] : porttcfolio.transient.cryptos;
    
    return (
        <span style={{width:"100%"}}>
            <Table className="cryptoPie" id="cryptoPie">
                <TableBody>
                    <Row className="cryptoPieRow">
                        <Col md="12" className="cryptoPieItemExpected">
                            Portefeuille actuel: ${porttcfolio.transient?.totalBeforeTax?.toFixed(0)} USDT
                        </Col>

                    </Row>
                    <Row className="cryptoPieRow">
                        <Col md="12" className="cryptoPieItem">
                            {renderPie(sortedItemsCryptos, false)}
                        </Col>
                    </Row>
                </TableBody>
            </Table></span>);
};

export default CryptoPiePerSymbolCurrentV2;